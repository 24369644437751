import Vue from "../../main.js";
import { apiUrl } from '@/app.config';

/**
 * Declare Variable
 */
const serviceUrl = apiUrl + "/user";
const allUrl = `${serviceUrl}`;
const get = `${serviceUrl}/data`;
const save = `${serviceUrl}/`;
const update = `${serviceUrl}/{id}`;
const block = `${serviceUrl}/block`;
const del = `${serviceUrl}/{id}`;

/**
 * Export
 */
export default {
  getAll() {
    return Vue.http.get(allUrl, {});
  },
  async get(email) {
    return Vue.http.get(get, { params: { email: email } });
  },
  async save(data) {
    return Vue.http.post(save, data, {});
  },
  async update(data) {
    return Vue.http.patch(update, data, {});
  },
  async block(data) {
    return Vue.http.patch(block, data, {});
  },
  delete(id) {
    return Vue.http.delete(del, { body: { id: id } });
  }
};
