<template>
  <v-layout justify-center class="dashboard-container">
    <v-flex xs12 sm12 md10>
      <material-card
        title="Users List/Lista de Usuarios"
        icon="mdi-account-group"
      >
        <v-card-text class="page-info dashboard-info">
          <v-btn color="primary" dark class="mb-2" to="user/new"
            >New/Nuevo</v-btn
          >
          <v-data-table
            :headers="headers"
            :items="list"
            class="elevation-1"
            :loading="isLoading"
          >
            <v-progress-linear
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.role }}</td>
              <td>{{ props.item.active }}</td>
            </template>
            <template
              v-slot:[`item.actions`]="{ item }"
              class="justify-center layout px-0"
            >
              <v-icon
                small
                class="mr-2"
                @click="edit(item)"
                title="Edit/Editar"
                v-if="item.active"
              >
                fas fa-edit
              </v-icon>
              <v-icon
                small
                @click="ban(item)"
                title="Ban/Bloquear"
                v-if="item.active"
              >
                fas fa-ban
              </v-icon>
              <v-icon
                small
                @click="ban(item)"
                title="Active/Activar"
                v-if="!item.active"
              >
                fas fa-star
              </v-icon>
            </template>
            <template slot="pageText" slot-scope="props">
              Lignes {{ props.pageStart }} - {{ props.pageStop }} de
              {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-card-text>
      </material-card>
    </v-flex>
    <v-dialog v-model="showBan" persistent max-width="400">
      <material-card>
        <v-card-title class="headline" v-if="banUser.active"
          >Block?/¿Bloquear?</v-card-title
        >
        <v-card-title class="headline" v-if="!banUser.active"
          >Active?/¿Activar?</v-card-title
        >
        <v-card-text>{{ banUser.email + " / " + banUser.name }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="action" color="blue" v-on:click.prevent="block"
            >Agree/Acepto
            <v-icon right>fas fa-check-circle</v-icon>
          </v-btn>
          <v-btn @click="showBan = false" color="red"
            >No
            <v-icon right>fas fa-ban</v-icon>
          </v-btn>
        </v-card-actions>
      </material-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import users from "../../services/api/users";

export default {
  name: "usersLists",
  data() {
    return {
      headers: [
        {
          text: "Email",
          align: "left",
          value: "email",
        },
        {
          text: "Name/Nombre",
          value: "name",
        },
        {
          text: "Role/Rol",
          value: "role",
        },
        {
          text: "Enabled/Activo",
          value: "active",
        },
        {
          text: "Actions/Acciones",
          value: "actions",
        },
      ],
      list: [],
      showBan: false,
      banUser: {},
    };
  },
  async created() {
    this.getData();
  },
  components: {
    MaterialCard: () => import("@/components/base/MaterialCard"),
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getData() {
      this.setLoading(true);
      try {
        const response = await users.getAll();
        this.list = response.body;
      } catch (e) {
        this.$toast.error(e.body.message);
      }
      this.setLoading(false);
    },
    edit(user) {
      this.$router.push({
        name: "useredit",
        params: {
          email: user.email,
        },
      });
    },
    ban(user) {
      this.showBan = true;
      this.banUser = user;
    },
    async block() {
      await users.block({
        id: this.banUser.id,
        active: !this.banUser.active,
      });
      this.showBan = false;
      this.getData();
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>

<style>
</style>
